























































































  import { vxm } from "@/store";
  import { Component, Vue, Watch } from "vue-property-decorator";
  import { validationMixin } from "vuelidate";
  import { email, required, } from "vuelidate/lib/validators";
  import firebase from "firebase/app";
  import "firebase/auth";

  import WaitModal from "@/views/widgets/modals/WaitModal.vue";
  import { Configuration, ProfileApiFactory, } from "@shared_vue/openapi/myvaultapi";
  import { UserManagerApiFactory } from "@shared_vue/openapi/userapi";
  import { Configuration as UserConfiguration } from "@shared_vue/openapi/userapi/configuration";
  import { LoginState } from "@/store/auth/types";
  import TbButton from "@/views/TbButton.vue";
  import OkModal from "@/views/widgets/modals/OkModal.vue";
  import GoogleAuthProvider = firebase.auth.GoogleAuthProvider;
  import { LoginDTO } from "@/types/ExtraTypes";
  import { CImg } from "@coreui/vue";

  @Component({
    components: { WaitModal, TbButton, OkModal },
    mixins: [validationMixin],
    validations: {
      form: {
        username: {
          required,
          email,
        },
        password: {
          required,
        },
      },
    },
  })
  export default class TheHeader extends Vue {
    private ui = vxm.ui;
    private auth = vxm.auth;
    private profileStore = vxm.profile;
    private darkMode: boolean = false;
    private form = this.getEmptyForm();
    private submitted = false;
    private hover = false;
    private publicPath = process.env.VUE_APP_STATIC_DIR;
    private myvaultApiPath = process.env.VUE_APP_MYVAULT_API_URL;
    private provider: GoogleAuthProvider | undefined;
    private showResetPasswordModal: boolean = false;
    private userMail = "";
    private showWait: boolean = false;
    private showWarning: boolean = false;
    private showOk: boolean = false;
    private errorMsg: string = "";
    private showPasswordReset = false;
    private goodMsg: string = "";
    private profileApi = ProfileApiFactory(
      <Configuration>{basePath: process.env.VUE_APP_MYVAULT_API_URL },
        process.env.VUE_APP_MYVAULT_API_URL
        );
        private userApi = UserManagerApiFactory(
        <UserConfiguration>{basePath: process.env.VUE_APP_USER_API_URL },
          process.env.VUE_APP_USER_API_URL
          );

          private toggleDarkMode() {
            this.ui.toggleDarkMode();
  }
          googleProvider() {
            // [START auth_google_provider_create]
            this.provider = new firebase.auth.GoogleAuthProvider();
          // [END auth_google_provider_create]

          // [START auth_google_provider_scopes]
          this.provider.addScope("https://www.googleapis.com/auth/userinfo.email");
          this.provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
    // this.provider.addScope('https://www.googleapis.com/auth/user.phonenumbers.read');
    // [END auth_google_provider_scopes]
  }

          @Watch("auth.idToken")
          onIdToken(val: boolean, oldval: boolean) {
            this.showWait = true;
          //redirect
          //NB this is not so simple anymore. We must check the state of the user
          //this.$router.push('/dashboard');
          const outerThis = this;
          console.log("id token. do what now?");
    //ignore if busy registering...
    if (this.ui.RegisterState > 1 && this.ui.registerState < 5) {
            console.log("ignore tho");
          this.showWait = false;
          return; //ignore
    }
          this.profileApi
          .profileGetProfile()
      .then((resp) => {
            console.log(resp.data);
          //add version stuff here :-O
          if (resp.status == 200 && resp.data && resp.data.userStatus) {
          if (resp.data.userStatus == 1) {
            console.log("ok this user is legit");
          this.profileStore.setProfile(resp.data);
          this.$router.push("/dashboard");
          }
        }
      })
          .catch(function (error) {
        if (error) {
            console.log(JSON.stringify(error));
          if (error.message.includes("404") || error.message.includes("403")) {
            //user signed in with google but does not exist
            //check state
            if (vxm.auth.loginState != LoginState.Registering) {
            console.log("needs to register!"); //NB he will exist on firebase now and have a guid
          vxm.auth.setState(LoginState.Registering);
          outerThis.userApi
          .userManagerRegisterFromGoogle()
                .then((res2) => {
                  //need user's cell
                  if (res2.status == 200) {
            outerThis.ui.registerState = 4;
          outerThis.ui.asideShow = true;
                  }
                })
                .catch((err) => {
            alert("something went wrong");
                });
            }
          }
        } else {
            console.log("empty error");
        }
      })
      .finally(() => {
            outerThis.showWait = false;
      });
  }

          private forgotPassFlow(){
            this.showPasswordReset = true;
  }

          private googleSignInPopup() {
            // [START auth_google_signin_popup]
            firebase
              .auth()
              .signInWithPopup(this.provider!)
              .then((result) => {
                /** @type {firebase.auth.OAuthCredential} */
                var credential: firebase.auth.AuthCredential | null = result.credential;

                console.log(result);
                console.log(credential);
                // This gives you a Google Access Token. You can use it to access the Google API.
                var token = credential!.providerId;
                // The signed-in user info.
                var user = result.user;
                // ...
              })
              .catch((error) => {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
              });
    // [END auth_google_signin_popup]
  }

          async submitgoogle() {
            this.googleSignInPopup();
  }

          private doTest() {
            console.log("Test Click using .native");
  }

          async test() {
            this.doTest();
  }

          mounted() {
            console.log('route parm: ' + this.$route.query.register == '2');
          this.googleProvider();
          if (this.$route.query.register == '2') {
            this.ui.asideShow = true;
          this.ui.registerState = 2;
    }
  }

          get ButtonImage() {
    if (this.hover) {
      return `${this.publicPath}images/btn_google_signin_dark_focus_web.png`;
    } else {
      return `${this.publicPath}images/btn_google_signin_dark_normal_web.png`;
    }
  }

          getEmptyForm() {
    return {
            username: "",
          password: "",
    };
  }

          get formString() {
    return JSON.stringify(this.form, null, 4);
  }

          get isValid() {
    return !this.$v.form.$invalid;
  }

          get isDirty() {
    return this.$v.form.$anyDirty;
  }

          checkIfValid(fieldName: any) {
    const field = this.$v.form[fieldName];
          if (field == undefined) {
      return false;
    }
          return !field.$dirty ? null : !(field.$invalid || field.$model === "");
  }

          validate() {
            this.$v.$touch();
  }

          reset() {
            this.form = this.getEmptyForm();
          this.submitted = false;
          this.$v.$reset();
  }

          async submit() {
    //mock actual login
    try {
            let dto = new LoginDTO(this.form.username, this.form.password);
          vxm.auth
          .loginNoRedirect(dto)
        .then((res) => {
            console.log("Succeeded with " + res);
          if (res) {
            this.$router.push("/dashboard/");
          }
        })
        .catch((err) => {
            this.errorMsg = err;
          this.showWarning = true;
        });
    } catch (err) {
            console.log("Failed with " + err);
    }
  }

          private resetPassword() {
            this.showResetPasswordModal = true;
  }

          private cancelPasswordReset() {
            this.userMail = "";
          this.showResetPasswordModal = false;
  }

          private async sendResetPassword() {
            this.showResetPasswordModal = false;
          this.showWait=true;
          await this.userApi.userManagerPasswordReset({email: this.userMail});
          this.showWait=false;
          this.goodMsg="Reset Link Sent...";
          this.showOk=true;
  }
}
